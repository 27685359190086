import CreamRose from "../../images/flowerDisplay/creamRose2.jpg";
import PurpleOrange from "../../images/flowerDisplay/purpleOrangeGarden.jpg";
import AutumnMix from "../../images/flowerDisplay/autumnMix.jpg";
import PinkGoodness from "../../images/flowerDisplay/pinkGoodness2.jpg";
import WhiteSplendor from "../../images/flowerDisplay/whiteSplendor3.jpg";
import RedHeart from "../../images/flowerDisplay/redRoseHeart.jpg";
import BlueExquisite from "../../images/flowerDisplay/blueExquisite.jpg";
import WeddingFlowers from "../../images/flowerDisplay/weddingFlowers.jpg";
import DarkPinkSplendor from "../../images/flowerDisplay/darkPinkSplendor.jpg";
import WhiteAndOrange from "../../images/flowerDisplay/whiteAndOrangeFlorals.jpg";
import RoseDelivery from "../../images/flowerDisplay/redRoseDelivery.jpg";
import PurpleLovlies from "../../images/flowerDisplay/purpleLovlies.jpg";
import PurpleExtravaganza from "../../images/flowerDisplay/purpleExtravaganza.jpg";
import GenderReveal from "../../images/flowerDisplay/teddyGenderReveal.jpg";
import PinkLoves from "../../images/flowerDisplay/pinkLoves.jpg";

export const FlowersData = [
    {
        id: 0,
        img: WhiteSplendor,
        alt: "White Splendor"
    },
    {
        id: 1,
        img: PurpleOrange,
        alt: "Purple, Orange Garden Mix"
    },
    {
        id: 2,
        img: PinkGoodness,
        alt: "Pink Goodness 2"
    },
    {
        id: 3,
        img: WeddingFlowers,
        alt: "Wedding Flowers - Red"
    },
    {
        id: 4,
        img: DarkPinkSplendor,
        alt:"Dark Pink Splendor"
    },
    {
        id: 5,
        img: PurpleExtravaganza,
        alt: "Purple Extravaganza"
    },
    {
        id: 6,
        img: AutumnMix,
        alt: "Autumn Mix"
    },
    {
        id: 7,
        img: RedHeart,
        alt:"Red Rose Heart"
    },
    {
        id: 8,
        img: CreamRose,
        alt:"Cream Rose 2"
    },
    {
        id: 9,
        img: PinkLoves,
        alt: "Pink Lovelies"
    },
    {
        id: 10,
        img: BlueExquisite,
        alt: "Blue Exquisite"
    },
    {
        id: 11,
        img: PurpleLovlies,
        alt: "Purple Lovelies"
    },
        {
        id: 12,
        img: WhiteAndOrange,
        alt: "White and Orange Mixture"
    },
    {
        id: 13,
        img: RoseDelivery,
        alt: "Red Rose Delivery"
    },
    {
        id: 14,
        img: GenderReveal,
        alt: "Teddy Bear - gender reveal"
    }
]