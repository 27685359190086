import React, { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from "react-ga";

const TRACKING_ID =  "UA-235376004-1";
ReactGA.initialize(TRACKING_ID);


function App() {

  AOS.init();

  useEffect(() => {

    ReactGA.initialize('UA-235376004-1')

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <>
      <Navbar />
      <AppRoutes />
      <Footer />
    </>
  );
}

export default App;
