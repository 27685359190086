import React from 'react';
import "./Navbar.css";
// import { FaBars } from "react-icons/fa";
import BloomLogo from "../images/logoNoBackground.png";

function Navbar() {
    return (
        <div className="navbarWrapper">
                {/* <a href="/" className="faBarsLink">
                    <FaBars className="faBarsIcon"/>
                </a> */}
                <a href="/home" className="logoWrapper">
                    <img 
                        className="businessLogo"
                        src={BloomLogo}
                        alt="Black and Bloom logo"
                    />
                    
                </a>  
        </div>
    )
}

export default Navbar;