import React from 'react';
import "./AboutDiana.css";
// import PinkSplendor from "../images/flowerDisplay/darkPinkSplendor.jpg";
import DianaVega from "../images/dianaVega.jpeg";

function AboutDiana() {
    return (
        <div className="aboutMeWrapper">
            <div className="aboutMeContainer">
                <div className="aboutMeWrap">
                    <div className="aboutMeImageWrapper">
                        <img 
                            className="aboutMeImage"
                            alt="Dark Pink Roses"
                            src={DianaVega}
                            data-aos="fade-up-left"
                            data-aos-duration='1000'
                            data-aos-once="true"
                            data-aos-delay="300"
                        />
                    </div>
                    <div className="aboutMeContentWrapper">
                        <div 
                            className='aboutMeContentContainer'
                            data-aos='fade-up'
                            data-aos-duration='1000'
                            data-aos-once='true'
                            data-aos-delay='1000'
                            data-aos-anchor-placement='center bottom'               
                            >
                            <div className="aboutMeHeader">Meet Diana</div>
                            <div className="dianaWords">"The most beautiful thing about working with flowers is the opportunity to give joy to others. My ultimate wish is for others to feel as much joy as I feel while I arrange them, and that sweet, sweet happiness in my heart when I look at the final result."</div>
                            <div className="aboutMeDescription">
                            It was one sunny August day in the first pandemic year when Diana stepped into Trader Joe’s. When she got home with a bunch of flowers, she had no idea that from then on, she will be delivering little joys. Black & Bloom Floral Design serves south Orange County and downtown San Diego. 
                            
                            </div>
                            <div className="instagramShoutout ">Visit  
                                <a 
                                    href="https://www.instagram.com/blackandbloomflowers/"
                                    className="blackandbloomInsta"
                                    rel="noopener noreferrer" 
                                    target="_blank" 
                                >@blackandbloomflowers</a> 
                                on Instagram!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutDiana;