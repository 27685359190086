import React from 'react';
import "./FlowerDisplay.css";
import { FlowersData } from "./data/FlowersData";

function FlowerDisplay() {
    return (
        <div className="ultimateFlowerDisplayWrapper">
            <div className="flowerDisplayWrapper">
                <div className="flowerDisplayContainer">
                    {/* <div className="flowerCard">
                        <img 
                            className="flowerImage"
                            src={WhiteSplendor}
                            alt="White Splendor 3"
                        />
                    </div> */}
                    {FlowersData.map((product, index)  => {
                        return (
                            <div 
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                className="flowerCard" key={index}>
                                <img 
                                    className="flowerImage"
                                    src={product.img}
                                    alt={product.alt}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FlowerDisplay;