import React, { useState } from "react";
import "./PayHere.css";
import PayPal from "./PayPal";
import gaPayNowSubmit from "../GoogleAnalytics/gaPayNowSubmit";

function PayHere() {

    const payNow = gaPayNowSubmit("Pay Now");

    const [cost, setCost] = useState(0);
    const [payConfirm, setPayConfirm] = useState(false);

    const handleAmount = ({ target: { value } }) => {

        payNow("Pay Now Button Clicked");
        console.log("AMOUNT", value);
        //store input in state
        setCost(Number(value));
    };

    return (
        <div className="payHereWrapper">
            <div className="payHereHeaderWrapper">
                <div className="payHereHeader">Pay Here</div>
            </div>
            {!payConfirm && (
            <div className="fullAmountHandledWrapper">
                <div className="amountHandledWrapper">
                    <div className="enterAmountWrapper">
                        <div className="enterAmountHeader">Enter amount of purchase:</div>
                    </div>
                    <div className="amountInputWrapper">
                        <div className="payInputWrapper">
                            $
                            <input
                                onChange={handleAmount}
                                name="orderPrice"
                                type="number"
                                className="orderPriceInput"
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="amountHandledButtonWrapper">
                        <button className="amountHandledButton" onClick={() => setPayConfirm(true)}>Confirm</button>
                </div>
            </div>
            )}
            <div className="payPalAppearanceWrapper">
                <div className="payHereButtonWrapper">
                    {payConfirm && <PayPal cost={cost} />}
                </div>
            </div>
        </div>
    );
}

export default PayHere;
