import React from 'react';
import AboutDiana from '../components/AboutDiana';
import FlowerDisplay from '../components/FlowerDisplay';
import Main from '../components/Main';
import OrderHere from '../components/OrderHere';
import PayHere from '../components/PayHere';

function Home() {

    
    return (
        <div>
            <Main />
            <FlowerDisplay />
            <AboutDiana />
            <OrderHere />
            <PayHere />
        </div>
    )
}

export default Home;