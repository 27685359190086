import React, {useState} from 'react';
import "./OrderHere.css";
import PurpleLove from "../videos/purpleBeauties.mp4";
import { potentialCustomerMessage } from "../api";
import toast, { Toaster } from "react-hot-toast";
import gaOrderNowButton from '../GoogleAnalytics/gaOrderNowButton';

const notify = () => toast("Message Sent!",
{
        icon: '💐',
        style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
        }
}
);

function OrderHere() {

    const gaOrderNow =  gaOrderNowButton("Submit Order Now Form");

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber]= useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async  (event) => {

        gaOrderNow("Submitted Order Now Form");

        const {data} =  await potentialCustomerMessage({name, email, phoneNumber, message});
        console.log("Customer info has been sent to server: ",  data);
        if(data.success){
            console.log("Successfully sent");
            notify();
            setName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');
        }
        
    }
    return (
        <div className="orderHereWrapper">
            <Toaster position="top-center"></Toaster>
            <div id="orderNow" className="orderHereInstructionsWrapper">
                <div className="orderHereHeaderWrapper">
                    <div className="orderHereHeader">Order Here</div>
                </div>
                <div className="orderNeededDetailsWrapper">
                    <div className="orderDetails">All arrangements are available for pick up or delivery.</div>
                </div>
                <div className="orderHereSubHeaderWrapper">
                    <div className="orderHereSubHeader">Send me a message and we will create the perfect bouquet together!</div>
                </div>
            </div>
            <div className="orderHereContactFormWrapper">
                <div className="orderHereContainer">
                    <video className="orderNowVideo" src={PurpleLove} playsInline autoPlay loop muted />
                    <div className="orderFormWrapper">
                        <div className="orderNowForm">
                            <div className="orderFormContent">
                                <div className="formName">Name:</div>
                                <input 
                                    name="name"     
                                    className=""
                                    onChange={event => setName(event.target.value)}
                                    value={name}
                                ></input>
                                <div className="formName">Email:</div>
                                <input 
                                    value={email}
                                    onChange={event => setEmail(event.target.value)} 
                                    name="email" 
                                    className=""
                                ></input>
                                <div className="formName">Phone number:</div>
                                <input 
                                    onChange={event => 
                                        setPhoneNumber(event.target.value)} 
                                    name="phoneNumber" 
                                    value={phoneNumber}
                                    className=""></input>
                                <div className="formName">Message:</div>
                                <textarea 
                                    className="messageTextArea" 
                                    onChange={event => setMessage(event.target.value)} 
                                    value={message}
                                    name="message"></textarea>
                                <div className="orderButtonWrapper">
                                    <button className="orderButton" onClick={handleSubmit}>Send Now</button>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderHere;