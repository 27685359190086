import ModalPopup from './ModalPopup';
import "./ModalPopup.css";

function Modal({ modalOpen, setModal}) {

    // const modalRef = useRef();

    // const closeModal = e => {

    //     console.log("backdrop has been clicked");
    //     if(modalRef.current === e.target){
    //         setModal(false);
    //     }
    // }

    return (
        <>
            <div className="modalBackground">
            </div>

            <div className="modalContainer">
            <ModalPopup 

                />
            </div>
        </>
    )
}

export default Modal;