import axios from "axios";

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}

const baseURL = process.env.NODE_ENV === 
    "production" ? 
    'https://blackandbloom.herokuapp.com' : 
    'http://localhost:3001';

    //email endpoint

    export const potentialCustomerMessage = body  => axios.post(`${baseURL}/email/customermessage`, body, {headers});
    export const customerEmailReceipt = body  => axios.post(`${baseURL}/email/emailreceipt`, body, {headers});