import React from "react";
import { Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Modal from "./components/Modal";

function AppRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/home" exact component={Home} />
      <Route path="/videopopup" component={Modal} />
    </Switch>
  );
}

export default AppRoutes;
