import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { customerEmailReceipt } from "../api";
import "./Paypal.css";
import toast, { Toaster } from "react-hot-toast";
const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function PayPal({ cost }) {

  const emailReceiptNotify = () => toast("Message Sent!",
    {
      icon: '📨',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff'
      }
    }
  );

  const [transactionDetails, setTransactionDetails] = useState(null);
  const [receiptEmail, setReceiptEmail] = useState(null);
  const [customEmail, setCustomEmail] = useState(null);

  const createOrder = useCallback(
    (data, actions) => {
      console.log("COST IN CREATE ORDER", cost);
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: cost,
            },
          },
        ],
      });
    },
    [cost]
  );

  const onApprove = (data, actions) => {
    // Finalize the transaction after payer approval

    return actions.order.capture().then(function (orderData) {
      // Successful capture! For dev/demo purposes:
      console.log("Capture result", orderData);

      setTransactionDetails(orderData);
    });
  };

  const setEmailPref = ({ target: { value } }) => {
    console.log("EMAIL PREF", value);
    setReceiptEmail(value);
  };

  const handleEmailReceipt = async () => {
    const recipient = receiptEmail === "custom" ? customEmail : transactionDetails.payer.email_address;
    await customerEmailReceipt({recipient, transactionDetails
    })
    emailReceiptNotify();
}

  return transactionDetails ? (
    <div className="transactionDetailsWrapper">
      <div className="transactionHeaderWrapper">
        <div className="transactionHeader">Transaction Complete!</div>
      </div>
      <div className="transactionContentWrapper">
        <div className="transactionPurchaser">
          Purchaser: {transactionDetails.payer.name.given_name}{" "}
          {transactionDetails.payer.name.surname}
        </div>
        <div className="transactionEmail">
          Email address: 
          <div className="transacationEmailHere">{transactionDetails.payer.email_address}</div>
        </div>
        <div className="transactionOrder">Order Number: {transactionDetails.id}</div>
        <div className="transactionStatus">Order Status: {transactionDetails.status}</div>

      </div>
      <hr />

      <div className="paymentDetailsWrapper">
        <div className="paymentDetailsHeader">Payment Details</div>
      </div>
      <div className="paymentDetailTotal">Total : {transactionDetails.purchase_units[0].amount.value}</div>
      <div className="whereEmailWrapper">
        <div className="whereEmailHeader">Where should we send the receipt?</div>
      </div>
      <div className="paypalEmail">
          <input
            onChange={setEmailPref}
            type="radio"
            id="paypal"
            name="receipt-preference"
            value="paypal"
          />
          <label for="paypal">{transactionDetails.payer.email_address}</label>
      </div>
      <div className="otherEmail">
          <input
            type="radio"
            onChange={setEmailPref}
            id="custom"
            name="receipt-preference"
            value="custom"
          />
          <label for="custom">Another Email</label>
          {receiptEmail === "custom" && (
            <div className="anotherEmailInputWrapper">
              <input
                className="anotherEmailInput"
                placeholder="Enter email address"
                onChange={({ target: { value } }) => setCustomEmail(value)}
              />
            </div>
          )}
      </div>
      <div className="emailReceiptButtonWrapper">
        <button className="emailReceiptButton" onClick={handleEmailReceipt} disabled={!receiptEmail}>Email Receipt</button>
      </div>
      </div>

  ) : (
    <PayPalButton
      createOrder={createOrder}
      onApprove={(data, actions) => onApprove(data, actions)}
    />
  );
}

export default PayPal;
