import React from 'react';
import "./Footer.css";
import {FaInstagram,  FaYoutube} from 'react-icons/fa';
import  "animate.css";

function Footer() {
    return (
        <div className="ultimateFooterWrapepr">
            <div className="deliveringHeaderWrapper">
                <div className="deliveringHeader animate__animated animate__pulse animate__infinite	infinite">Delivering little joys.</div>
            </div>


            <div className="footerMainWrapper">
                <div className="footerSocialWrapper">
                    <a
                        target = '_blank'
                        className="instagramLink"
                        href="https://www.instagram.com/blackandbloomflowers/" rel="noreferrer"
                    >
                        <FaInstagram className="firstLogo"/>
                    </a>
                    <a
                        target = '_blank'
                        className="youtubeLink"
                        href="https://www.youtube.com/channel/UCyM3ddNgPKVmfml7ZuU5Z2w" rel="noreferrer"
                    >
                        <FaYoutube className="socialLogo"/>
                    </a>
                </div>
            </div>
            <div className="builtByWrapper">
                <div className="builtbySophia">Website designed & 
                    <a className ="builtByLink" href="https://www.builtbysophia.com/" rel="noreferrer" target = '_blank'>
                        Built By Sophia
                    </a>
                </div>
            </div>

            <div className="allRightsWrapper">
                <div className="allRights">Copyright ⓒ 2022, Black and Bloom Floral Design. All Rights Reserved.</div>
            </div>
        </div>

    )
}

export default Footer;