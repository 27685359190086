import React from 'react';
import { MdClose } from "react-icons/md";
import "./ModalPopup.css";

function ModalPopup({showModal, setShowModal}) {
    return (
        <div className="modalComponent">
            <div 
                className="modalPopupContainer"
                style={{zIndex: 102}}
                >
                    <iframe className="blackBloomVideo" src="https://www.youtube.com/embed/NKStagXMZQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <a className="closeButtonLink" href="/home">
                        <MdClose
                        className="modalPopupCloseButton"
                        aria-label="Close modal"
                        // onClick={() => {setShowModal(prev => !prev)}}
                    />
                    </a>


            </div>
        </div>
    )
}

export default ModalPopup