import ReactGA from "react-ga";

function gaWatchTrailer(category="Trailer") {
    const eventTracker = (action = "Watch Trailer", label = "Clicked Watch Trailer Button") => {
        ReactGA.event({category, action, label});
    }
    return eventTracker;
}


export default gaWatchTrailer;