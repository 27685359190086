import React from 'react';
import "./Main.css";
// import { Link } from "react-router-dom";
import DianaBloom from "../videos/floraldesigns.mp4";
import { AiFillPlayCircle } from "react-icons/ai";
import MainStill from "../images/mainVideoStill.png";
import gaWatchTrailer from '../GoogleAnalytics/gaWatchTrailer';

function Main() {

    const watchTrailer = gaWatchTrailer("Watch Trailer");


    return (
        <div className="mainWrapper">
            <video className="mainVideo" poster={MainStill} src={DianaBloom} playsInline autoPlay loop muted />
            <div className="mainWordingWrapper"
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-once='true'
                data-aos-delay='1000'
                data-aos-anchor-placement='center bottom' 
            >
                <div className="mainHeadingWrapper">
                    <div className="mainHeading">BLACK & BLOOM</div>
                </div>
                <div className="secondMainHeadingWrapper">
                    <div className="secondMainHeading">FLORAL DESIGN</div>
                </div>
                <div className="mainSubtitleWrapper">
                    <div className="italizedSubtitle">Delivering little joys.</div>
                </div>
                <div className="secondMainSubtitleWrapper">
                    <div className="mainSubtitle">Serving south Orange County & downtown San Diego.</div>
                </div>
                <div className="mainButtonWrapper">
                    <div className="watchTrailerButtonWrapper">
                        <a
                            className="trailerButton" 
                            href="/videopopup"
                            onClick={() => watchTrailer("Clicked Watch Trailer Button")}
                        >
                        WATCH TRAILER
                            <AiFillPlayCircle className="playIcon" style={{marginLeft: "4px"}}/>
                        </a>
                    </div>
                    <div className="shopNowButtonWrapper">
                        <a 
                            href="#orderNow"
                            className="shopButton" 
                        >
                        ORDER NOW
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;